import React, { useState, setTimeout, useEffect } from "react";
import FirebaseContactForm from '../components/FirebaseContactForm'
import { Disqus } from 'gatsby-plugin-disqus';
import { TbBrandDisqus } from "react-icons/tb";
import { FiMail } from "react-icons/fi";
import "../styles/contact-form.css"

export default ({ post }) => {

    
    const [displayDisqusToggle, setDisplayDisqusToggle] = useState(false);
    const [displayMessageToggle, setDisplayMessageToggle] = useState(false);

    const handleDisqusOnClick = (e) => { 
      setDisplayDisqusToggle(!displayDisqusToggle) 
      setDisplayMessageToggle(false) 
    }

    const handleMessageOnClick = (e) =>  { 
      setDisplayMessageToggle(!displayMessageToggle) 
      setDisplayDisqusToggle(false) 
    }

    const getDisqusClasses = () => {
      if(displayDisqusToggle)
      {
        return 'displayed'
      }

      return 'hidden'
    }

    const getMessageClasses = () => {
      if(displayMessageToggle)
      {
        return 'contact-form displayed'
      }

      return 'contact-form hidden'
    }

  return (
    <div style={{
      textAlign: 'center'
    }}>
      <style>{`
        .hidden {
          display: none;
        }
        .displayed {
          display: block;
        }
        .tabs {
          line-height: 1.6;
        }

        .tabs > * {
          padding: 1em;
        }
        .tab-content > * {
          padding: 1em;
        }

        button{
            background: #fff;
            border: 0;
            text-transform: uppercase;
        }
        button:hover, button:focus{
            background: #92bde7;
            color: #fff;
            outline: 0;
            transition: backgound-color 2s ease-out;
        }
      `}</style>
      <div className='tabs' >
      <button onClick={handleDisqusOnClick}>
      <TbBrandDisqus  size='20px' style={{
              margin: '0 4px -3px 2px'}}/></button>
      <button onClick={handleMessageOnClick}>
      <FiMail  size='20px' style={{
              margin: '0 4px -3px 2px'}}/></button></div>
      <div className="tab-content">
        <div className={getMessageClasses()}><FirebaseContactForm settings={{message: 'About your post...', data: post}} /></div>
        <Disqus className={getDisqusClasses()}
              config={{
                  url: `https://www.do-wot.com/posts/${post.slug}`,
                  identifier: `${post.slug}`,
                  title: `${post.title}`,
              }}
          />
      </div>
    </div>);
}